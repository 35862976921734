@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700);

/*
primary-color: var(--primary)
*/
body,
html {
  height: 100%;
}

:root {
  --primarybg: #7166f933;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #7166f9;
  --secondary: #6c757d;
  --success: #4caf50;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

body {
  background-color: #f8f8f9;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: black;
  font-size: 14px;
}

#root {
  height: 100%;
}

.page-link {
  color: var(--primary);
}

.card {
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}

.card-header,
.card-footer {
  background-color: white;
  border: none;
  border-radius: 5px !important;
  padding: 1rem;
}

.card-body {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h4,
h5,
h6 {
  font-weight: 700;
}

.btn {
  border-radius: 5px;
  padding: 0.75rem 0.75rem;
  font-weight: bold;
}

.btn-primary {
  color: #fff;
  background-color: var(--primary);
  border: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #332f63;
  border-color: #332f63;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #332f63;
  border-color: #332f63;
}

.btn-primary:hover {
  color: #fff;
  background-color: #4d47a7;
  border: none;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: var(--primary);
  outline: 0;
  box-shadow: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0.3rem 0.1rem rgba(0, 0, 0, 0.11);
}

body.modal-open #the-content {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}

.btn>span,
.dropdown-item>span {
  margin-left: 0.5rem;
  font-size: 14px;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--primary);
}

.edit-buttons {
  padding: 0.4rem 0.4rem;
  line-height: 0;
  margin-right: 5px;
}

tr {
  border-bottom: 1px solid #dee2e6;
}

td,
th {
  white-space: nowrap;
  font-size: 0.8rem;
  border: 1px solid #dee2e6;
}

td:last-child {
  border: none;
}

td:first-child {
  border: none;
}

th:first-child {
  border-left: none;
}

th:last-child {
  border-right: none;
}

th {
  border-bottom: none;
}

input.form-control.table-input {
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0px;
  background: #0000000d;
}

input.form-control.table-input:focus {
  border-bottom: 1px solid var(--primary);
}

.table td {
  padding: 0.5rem;
}

.table td,
.table th {
  border-top: none;
}

.btn-link:focus {
  outline: 0;
  box-shadow: none;
}

.btn-link {
  padding: 0px;
}

.table td .btn-link {
  font-size: 0.8rem;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.404);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0;
}

a,
.btn-link {
  color: var(--primary);
}

a:hover,
.btn-link:hover {
  color: #4640a0;
}

.table_col tr {
  border: none;
}

.table_col {
  flex: 0.5;
}

.table_col td {
  white-space: normal;
  padding: 5px 10px;
  vertical-align: top;
}

.table_col td:nth-child(2n) {
  width: 60%;
  border-right: 0.5px solid rgba(139, 139, 139, 0.432);
}

.table_col td div.form-group {
  margin-bottom: 0;
}

.table_col td .form-group {
  margin-bottom: 0;
}

.table_col td .form-control {
  min-height: 1.8rem;
}

.table_col_4 {
  flex: 0.5;
}

.table_col_4 td {
  white-space: normal;
  padding: 5px 10px;
  vertical-align: top;
  border: 0;
}

.table_col_4 td:nth-child(2n) {
  border-right: 0.5px solid rgba(139, 139, 139, 0.432);
}

.table_col_4 tr {
  white-space: normal;
  padding: 5px 10px;
  vertical-align: top;
  border: 0;
}

.table-no-border td {
  border: none;
}

.table-no-border tr {
  border: none;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: black;
}

.dropdown-menu.show {
  width: auto !important;
}

.card_header_primary {
  background: #7166f926;
  color: var(--primary);
}

.card_header_grey {
  background: #e4e4e4;
}

.alert-primary {
  color: #7d73f9;
  font-weight: bold;
  background-color: #7066f92f;
  border: none;
}

.modal-header {
  border-bottom: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

.modal-content {
  border: none;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.form-control {
  padding: 0.375rem 0.75rem;
  font-size: inherit;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: calc(100% - 1rem);
    margin: 1.75rem auto;
  }
}

.trasparent-bg {
  background: transparent !important;
}